<template>
  <div>
    <a-card :tab-list="tabList" :active-tab-key="currentTab" @tabChange="tabChange">
      <div v-if="currentTab === 'ReceivableArrearsPanel'">
        <ReceivableArrearsPanel />
      </div>
      <div v-if="currentTab === 'ReceiptRecordPanel'">
        <ReceiptRecordPanel />
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  components: {
    ReceivableArrearsPanel: () => import("./ReceivableArrearsPanel"),
    ReceiptRecordPanel: () => import("./ReceiptRecordPanel"),
  },
  data() {
    return {
      tabList: [
        {
          key: "ReceivableArrearsPanel",
          tab: "应收账款",
        },
        {
          key: "ReceiptRecordPanel",
          tab: "收款记录",
        },
      ],
      currentTab: undefined,
    };
  },
  methods: {
    initData() {
      let currentTab = this.$route.query.currentTab;
      if (currentTab) {
        this.currentTab = currentTab;
      } else {
        this.currentTab = this.tabList[0].key;
        this.$router.push({ query: { currentTab: this.currentTab } });
      }
    },
    tabChange(key) {
      this.currentTab = key;
      this.$router.push({ query: { currentTab: this.currentTab } });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
